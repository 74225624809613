import React from "react";
import Navigation from '../components/navigation';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Feed from "../components/feed";
import { graphql } from 'gatsby';
import Footer from '../components/footer';


const portrait = (props) => {
  
  const portraitContent = props.data.allContentfulWork;  

  return (
    <Layout>
      <SEO title="Portrait" keywords={[`photographer`, `fotograf`, `dusseldorf`, `düsseldorf`, `germany`, `写真家`, `portrait`, `porträt`, `カメラマン`, `TFP`, `landscape`, `hobby`, `japanese`, `street`, `outdoor`]} />
      <Navigation portrait={true} />
      <Feed data={portraitContent} />
      <Footer />
    </Layout>
  );
};

export default portrait;

export const query = graphql` 
query PortraitQuery {
  allContentfulWork(
      sort: { fields: [createdAt], order: DESC }
      filter: {
      node_locale: {eq: "en-US"},
      category: {category: {eq: "portrait"}},
    }
    ) {
    edges {
      node {
        id
        title
        picture {
          fluid(maxWidth: 600, quality: 95) {
            src
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
}
`
